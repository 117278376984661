export const API_ENDPOINTS = {
    LOGIN: '/api/login.php',
    REGISTER: '/api/register.php',
    FORGOT_PASSWORD:'/api/forgot_password.php', // silver gold
    PLAYER_PROFILE:'/api/get-player-profile.php',
    FEATURED_PACKAGES:'/api/getFeaturedPackages.php',
    PACKAGES:'/api/getPackages.php',
    USER_INVOICE:'api/getUserInvoice.php',  
    GET_CHAT:'api/chat-api.php/messages',  
    GET_USERS:'api/getOnlineUsers.php',
    GET_FRIENDS:'api/getFriends.php',
    GET_FRIEND_REQUEST:'api/getFriendRequest.php',
    GET_PLAYERS:'api/getPlayers.php',
    SEND_CHAT:'api/chat-api.php/messages',
    GET_CHARACTERS:'api/getCharacters.php',
    SEND_FRIEND_REQUEST:'api/sendFriendRequest.php',
    ACCEPT_FRIEND_REQUEST:'api/acceptFriendRequest.php',
    DECLINE_FRIEND_REQUEST:'api/declineFriendRequest.php',
    BLOCK_FRIEND:'api/blockFriend.php',
    UNBLOCK_FRIEND:'api/unblockFriend.php',
    BLOCK_FRIEND_LIST:'api/getBlockFriendsList.php',
    GET_MAIL_LIST:'api/getMail',
    POKEMONS:'api/getPokemons.php',
    PLAYER_POKEMONS:'api/getPlayerPokemon.php',
    UPDATE_PLAYERS:'api/updatePlayer.php'
};