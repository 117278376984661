import React from 'react'
import GoldSiverHeader from '../HomePage/GoldSiverHeader'

function Pokedex() {
  return (
    <div>
      <GoldSiverHeader previous={'/home'} title='Pokedex'>
        <section class="arPokedex_area_section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="ar_pokedex_area">
                  <div class="ar_pokedex_area_left">
                    <h4>תפסא:</h4>
                    <h4>15 םינומיקופ</h4>
                    <div class="ar_pokedexLeft_parcent">
                      <img src="images/pokedex/parcebtage.png" alt=""/>
                    </div>
                    <div class="ar_pokedex_prcent_btn">
                      <a href="#"><img src="images/pokedex/parbtn1.png" alt=""/></a>
                      <a href="#"><img src="images/pokedex/parbtn1.png" alt=""/></a>
                    </div>
                  </div>
                  <div class="ar_pokedex_area_right">
                    <div class="arPokedex_areaScrool">
                      <div class="ar_pokedex_item_wrapper_area">
                        <a href="#"><img src="images/pokedex/yelloIcon.png" alt=""/>ילמשח גוס </a>

                        <div class="arPokedex_wrapper_itam">
                          <a href="#"><img src="images/pokedex/eletricshow.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                        </div>
                      </div>

                      <div class="ar_pokedex_item_wrapper_area arPinkicon">
                        <a href="#"><img src="images/pokedex/pinkicon.png" alt=""/>ילמשח גוס </a>

                        <div class="arPokedex_wrapper_itam">
                          <a href="#"><img src="images/pokedex/eletricshow.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                          <a href="#"><img src="images/pokedex/eletricshow2.png" alt="images"/></a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section></GoldSiverHeader>
    </div>
  )
}

export default Pokedex
