import React from 'react'
import GoldSiverHeader from '../HomePage/GoldSiverHeader'

function Settings() {
    return (
        <div>   
            <GoldSiverHeader previous={'/home'} title='Settings'>
            <section className="ar_myProfile_area_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ar_myProfile_area">
                                <div className="ar_myProfile_itam_wrapper">
                                    <div className="ar_myProfile_single_item">
                                        <div className="ar_myProfile_sinle_title">
                                            <a href="#"><img src="images/myAccount/shap.png" alt=""/>ישיא עדימ</a>
                                        </div>
                                        <div className="ar_myProfile_swithc_area">
                                            <div className="ar_myProfile_sinlge_switch_area">
                                                <div className="ar_myProfile_sinlge_switch">
                                                    <label className='toggle-label'>
                                                        <input type='checkbox' />
                                                        <span className='back'>
                                                            <span className='toggle'></span>
                                                            <span className='label on'>אל</span>
                                                            <span className='label off'>ןכ</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="ar_myProfile_sinlge_text">
                                                    <p>:ליפורפב תווצ הארה</p>
                                                </div>
                                            </div>
                                            <div className="ar_myProfile_sinlge_switch_area">
                                                <div className="ar_myProfile_sinlge_switch">
                                                    <label className='toggle-label'>
                                                        <input type='checkbox' />
                                                        <span className='back'>
                                                            <span className='toggle'></span>
                                                            <span className='label on'>אל</span>
                                                            <span className='label off'>ןכ</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="ar_myProfile_sinlge_text">
                                                    <p>טאצ</p>
                                                </div>
                                            </div>
                                            <div className="ar_myProfile_sinlge_switch_area">
                                                <div className="ar_myProfile_sinlge_switch">
                                                    <label className='toggle-label'>
                                                        <input type='checkbox' />
                                                        <span className='back'>
                                                            <span className='toggle'></span>
                                                            <span className='label on'>אל</span>
                                                            <span className='label off'>ןכ</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="ar_myProfile_sinlge_text">
                                                    <p>:ברק ודל הנמזה</p>
                                                </div>
                                            </div>
                                            <div className="ar_myProfile_sinlge_switch_area">
                                                <div className="ar_myProfile_sinlge_switch">
                                                    <label className='toggle-label'>
                                                        <input type='checkbox' />
                                                        <span className='back'>
                                                            <span className='toggle'></span>
                                                            <span className='label on'>אל</span>
                                                            <span className='label off'>ןכ</span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="ar_myProfile_sinlge_text">
                                                    <p>:ליפורפב ןומיקופה</p>
                                                    <p>דמעמ תא גיצמ</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ar_myProfile_range_area">
                                            <div className="ar_myProfile_range_text">
                                                <p>30%</p>
                                                <p>:םוילוו</p>
                                            </div>
                                            <div className="ar_myProfile_range">
                                                {/* <label for="customRange1" className="form-label">Example range</label> */}
                                                <input type="range" className="form-range" id="customRange1"/>
                                            </div>
                                        </div>
                                        <div className="ar_myProfile_btn">
                                            <a href="#"><img src="images/myAccount/accBtn.png" alt=""/></a>
                                            <div className="ar_myProfile_btn_text">
                                                <p>רומשל </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ar_myProfile_single_item">
                                        <div className="ar_myProfile_sinle_title">
                                            <a href="#"><img src="images/myAccount/shap.png" alt=""/>ישיא עדימ</a>
                                        </div>
                                        <div className="ar_myProfile_sinlge_switch">
                                            <label className='toggle-label big_toggle_label'>
                                                <input type='checkbox' />
                                                <span className='back'>
                                                    <span className='toggle ar_toggle'></span>
                                                    <span className='label on ar_on'> המסיס </span>
                                                    <span className='label off ar_off'> ליימיא </span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="arMyprofile_btn_cont">
                                            <div className="ar_myProfile_btnCont_single">
                                                <a href="#"><img src="images/myAccount/input.png" alt=""/></a>
                                                <div className="ar_myProfile_btnCont_text">
                                                    <p>לעופב אמסיס</p>
                                                </div>
                                            </div>
                                            <div className="ar_myProfile_btnCont_single">
                                                <a href="#"><img src="images/myAccount/input.png" alt=""/></a>
                                                <div className="ar_myProfile_btnCont_text">
                                                    <p>השדח המסיס</p>
                                                </div>
                                            </div>
                                            <div className="ar_myProfile_btnCont_single">
                                                <a href="#"><img src="images/myAccount/input.png" alt=""/></a>
                                                <div className="ar_myProfile_btnCont_text">
                                                    <p>המסיס רשא</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ar_myProfile_btn">
                                            <a href="#"><img src="images/myAccount/accBtn.png" alt=""/></a>
                                            <div className="ar_myProfile_btn_text">
                                                <p>רומשל </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ar_myProfile_single_item">
                                        <div className="ar_myProfile_sinle_title">
                                            <a href="#"><img src="images/myAccount/shap.png" alt=""/>ישיא עדימ</a>
                                        </div>

                                        <div className="ar_myProfile_select_area">
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>םע ףתש</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div className="ar_myProfile_btn">
                                            <a href="#"><img src="images/myAccount/accBtn.png" alt=""/></a>
                                            <div className="ar_myProfile_btn_text">
                                                <p>רומשל </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </GoldSiverHeader>
        </div>
    )
}

export default Settings
