import React from 'react'
import GoldSiverHeader from '../HomePage/GoldSiverHeader'

function Notification() {
    return (
        <div>
            <GoldSiverHeader previous={'/home'} title='Notifications'>
                <section class="ar_work_area_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="ar_work_area_main">
                                    <div class="ar_work_area">
                                        <div class="ar_single_item_table_work heading">
                                            <div class="ar_work_single_text text heading">
                                                <p>Notifications</p>
                                            </div>
                                        </div>
                                        <div class="ar_workFlex_wrapper">
                                            <div class="ar_single_item_table_work hard">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Hard)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work easy">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Normal)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work normal">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Easy)</span>Help to clean your town</p>
                                                </div>
                                            </div>
                                            <div class="ar_single_item_table_work hard">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Hard)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work easy">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Normal)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work normal">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Easy)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work hard">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Hard)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work easy">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Normal)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                            <div class="ar_single_item_table_work normal">
                                                <div class="ar_work_single_text text">
                                                    <p><span>(Easy)</span>Help to clean your town</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="ar_work_btn">
                                            <div class="ar_work_single_btn">
                                                <img src="assets/images/work/input.png" alt="" />
                                            </div>
                                            {/* <div class="ar_work_single_btn">
                                    <a href="#"><img src="assets/images/work/workbtn2.png" alt=""/></a>
                                    <div class="ar_work_btn_xont">
                                        <p>רומשל </p>
                                    </div>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                .
                            </div>
                        </div>
                    </div>
                </section>
            </GoldSiverHeader>
        </div>
    )
}

export default Notification
