import React from 'react'
import GoldSiverHeader from '../HomePage/GoldSiverHeader'

function Hospital() {
    return (
        <div>
            <GoldSiverHeader previous={'/home'} title='Hospital'>
                <section class="ar_hospital_area_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="ar_hospital_area">
                                    <img src="images/hospital/hospital-bg.png" alt=""/>
                                        <div class="ar_hospital_recovery">
                                            <img src="images/hospital/recovery.png" alt=""/>
                                        </div>
                                        <div class="ar_hotpita_btnCoin">
                                            <a href="#"><img src="images/hospital/hosbtn.png" alt=""/></a>
                                            <a href="#"><img src="images/hospital/btnplace.png" alt=""/></a>
                                        </div>
                                        <div class="ar_hospital_item_single">
                                            <img src="images/hospital/ho1.png" alt=""/>
                                                <img src="images/hospital/ho2.png" alt=""/>
                                                    <img src="images/hospital/ho2.png" alt=""/>
                                                        <img src="images/hospital/ho2.png" alt=""/>
                                                            <img src="images/hospital/ho2.png" alt=""/>
                                                                <img src="images/hospital/ho2.png" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </GoldSiverHeader>
                                </div>
                                )
}

                                export default Hospital
